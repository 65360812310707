<template>
  <div id="contact">
    <Navigation/>
    <div class="contact-container">
      <div>
        <div class="title animate__animated animate__fadeInDown">Contact us</div>
        <div class="contact-body">
          <div class="content">
            <div class="subtitle animate__animated animate__fadeInDown">비즈니스에 필요한 모든 기술 문서 컨텐츠를 제작 및 관리할 수 있는 파트너를 고용할 준비가 되셨습니까?</div>
            <ContactForm v-if="pages[pageIndex] == 'form'"/>
            <Map location="ko" v-if="pages[pageIndex] == 'ko'"/>
            <Map location="ph" v-if="pages[pageIndex] == 'ph'"/>
          </div>
          <div class="contact-pane animate__animated animate__fadeInRight">
            <div class="panes">
              <div class="pane">
                <div class="pane-title">Get in touch</div>
                <div class="pane-body" v-html="address"></div>
              </div>
              <div class="pane">
                <div class="pane-title">Phone</div>
                <div class="pane-body" v-html="phone"></div>
              </div>
              <div class="pane">
                <div class="pane-title">Contact</div>
                <div class="pane-body" v-html="email"></div>
              </div>
            </div>
            <div class="buttons">
              <a class="pane-button" href="/assets/files/[텍스트리] 서비스 소개서 전체 (2024 Ver).pdf" download>
                <img src="/assets/icons/download.png" alt="">
                <span>회사소개서</span>
              </a>
              <a class="pane-button" href="/assets/files/텍스트리_프리랜서_번역사_지원양식.XLSX" download>
                <img src="/assets/icons/translate.png" alt="">
                <span>번역가 지원</span>
              </a>
              <!-- <a class="pane-button" href="https://latis.recruiter.co.kr/appsite/company/index" target="_blank">
                <img src="/assets/icons/stacks.png" alt="">
                <span>입사 지원</span>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <More @next="pageMove"/>
    <MobileMore/>
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav'),
    More: () => import('@/components/more'),
    MobileMore: () => import('@/components/mobile-more'),
    ContactForm: () => import('./form'),
    Map: () => import('./map')
  },
  computed: {
    address: function () {
      if (this.pages[this.pageIndex] == 'ph') {
        return '2F iL Centro,<br>Sta. Lucia East Grand Mall,<br>San Isidro, Cainta, Rizal'
      }
      return '서울시 송파구 삼학사로 33<br>(삼전동 182-13) 4F'
    },
    phone: function () {
      if (this.pages[this.pageIndex] == 'ph') {
        // todo
      }
      return '070-8270-3640'
    },
    email: function () {
      if (this.pages[this.pageIndex] == 'ph') {
        // todo
      }
      return 'contact@textree.co.kr'
    }
  },
  data: () => ({
    pages: [
      'form',
      'ko',
      'ph'
    ],
    pageIndex: 0
  }),
  mounted() {
    store.commit('SET_NAV_PAGE', 'contact')
    store.commit('SET_NAV_THEME', 'dark')
    store.commit('SET_MORE_THEME', 'dark')
    store.commit('SET_MORE_PAGES', this.pages)
    store.commit('SET_MORE_HAS_SCROLL', true)
  },
  methods: {
    pageMove: function (index) {
      this.pageIndex = index
    }
  }
}
</script>
<style scoped>
div#contact {
  position: absolute;
  width: 100%;
  height: calc(100% - 66px - 64px);
  top: 66px;
  bottom: 64px;
  overflow-y: scroll;
}

div.contact-container {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
}
div.contact-container > div {
  margin: 48px 24px 0 200px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

div.title {
  font-size: 500%;
  font-family: "Montserrat";
  font-weight: 600;
}

div.contact-body {
  display: flex;
}

div.content {
  flex: 1 1 calc(100% - 440px);
  margin-right: 24px;
}
div.contact-pane {
  background: #008ED6;
  flex: 0 0 calc(440px - 48px);
  padding: 24px;
}

div.pane-title {
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: bold;
  line-height: 80px;
  color: white;
}
div.pane-body {
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: normal;
  line-height: 30px;
  color: white;
  margin-top: -12px;
}

a.pane-button {
  width: 180px;
  border: 1px solid #F1F1F1;
  border-radius: 3px;
  font-size: 18px;
  line-height: 22px;
  padding: 12px 24px;
  color: white;
  margin: 12px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
}
a.pane-button img {
  width: 24px;
  margin-right: 8px;
}
div.panes {
  padding-bottom: 12px;
}
div.buttons {
  padding-top: 24px;
}

@media(max-width: 1600px) {
  div.contact-container > div {
    margin: 24px 24px 0;
  }
}

@media(max-width: 1024px) {
  div.contact-container {
    max-width: 100%;
    display: block;
  }
  div.contact-pane {
    flex-basis: 325px;
  }
  div.content {
    width: 100%;
  }
}

@media(max-width: 960px) {
  div.content {
    margin-right: 0;
  }
  div.contact-pane {
    display: none;
  }
  div.title {
    font-size: 300%;
  }
}

@media(max-width: 576px) {
  div.title {
    font-size: 300%;
  }
}

@media(max-width: 425px) {
  div.title {
    font-size: 200%;
  }

  div.content {
    margin-right: 0;
    font-size: 12px;
    line-height: 30px;
  }
}
</style>


<style>
#plusfriend-chat-button {
  bottom: 64px;
}
#plusfriend-chat-button a {
  right: 36px;
}

@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 48px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 64px;
  }
}
</style>
